import React, { useEffect, useRef, useState } from 'react'
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { submitReRegistrationService } from '../../services/ReRegistrationService';
import Swal from 'sweetalert2';
import Select from "react-select";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setReRegistrationsReload } from '../../store/actions';
import DigitalSignatureComponent from '../common/DigitalSignatureComponent';

export default function SchoolOfGreenside(props) {
  const { reRegSubject, registration, setReadOnly, setStudentNewRegId, lateReg } = props;
  const [loading, setLoading] = useState(false);
  const [disableReg, setDisableReg] = useState(false);
  const [disableRep, setDisableRep] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const isReReg = useSelector(state=>state.isReReg);
  const reload = useSelector(state => state.registrations.reload);
  const repeatOptions = [
    {value : 4,label :'Critical Studies'},
    {value : 5,label :'Design Studio, Visual Communication and Technology and Professional Practice'},
    {value : 6,label :'Minor Module'},
    {value : 7,label :'Major Module'},
  ]
  const [isSig, setIsSig] = useState("")
  const signaturePadRef = useRef(null);
  const fileInputRef = useRef(null);
  const [registerFor,setRegisterFor] = useState("")
  const [repeat, setRepeat] = useState([""])
  const [checked, setChecked] = useState(false)
  const [ipAddress, setIpAddress] = useState('');
  const [cDate,setCDate] = useState('')
  const [isCancel, setIsCancel] = useState(false)
  const [emptyFields, setEmptyFields] = useState(false)

  const ReRegisterSchema = Yup.object({
    // registerFor : Yup.string().when(['repeat'],{
    //   is:(repeat)=>(repeat.filter((val)=>val!="").length>0),
    //   then:Yup.string().required("register for is required"),
    // }), 
    // repeat : Yup.mixed().when('registerFor',{
    //   is : '',
    //   then:Yup.array().of(Yup.string().required("required::"))
    // }),
    term_condn: Yup.bool().oneOf([true], "Accept Terms & Conditions is required"),
    student_signature : Yup.mixed().required('Signature is required'),

  })
  // {
  //   let flag = true;
  //   repeat.map((val)=>{if(val.length>0){flag=false}})
  //   return flag;
  // }
  // {
  //   email: yup.mixed()
  //     .when('isArray', {
  //       is: Array.isArray,
  //       then: yup.array().of(yup.string()),
  //       otherwise: yup.string(),
  //     })
  // }

  const handleCancel = () => {
    setIsCancel(true)
    setEmptyFields(true)
    setIsSig("")
    setChecked(false)
    setIpAddress('')
    setCDate('')
    setRepeat([''])
    setRegisterFor('')
    
    setTimeout(() => {
      setIsCancel(false);
      setEmptyFields(false)
    }, 100);
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      rereg_academy: registration.academy,
      rereg_id: registration.id,
      registerFor: registerFor,
      repeat: repeat,
      term_condn: checked,
      late_reg : lateReg,
      student_signature : isSig,
      ip: ipAddress,
      signature_date:parseInt(new Date(cDate).getTime()/1000)
    },
    validationSchema: ReRegisterSchema,
    onSubmit: (values) => {
      setLoading(true);
      const sendData = {...values};
      sendData.repeat = sendData.repeat.join()
      const formData = new FormData();
  
  // Append all values from the form
  Object.keys(values).forEach(key => {
    // Special handling for repeat field to convert array to comma-separated string
    if (key === 'repeat' && Array.isArray(values[key])) {
      formData.append(key, values[key].join(','));
    } else {
      formData.append(key, values[key]);
    }
  });
      submitReRegistrationService(formData).then((res) => {
        Swal.fire({
          icon: "success",
          title: "Successfully Submitted",
          text: "This does not confirm approval of your re-registration!!",
          timer: 3000,
          onOpen: function () {
            Swal.showLoading();
          },
        }).then(function (result) {
          setLoading(false);
          setStudentNewRegId(res.data.studentNewRegId);
          setReadOnly(true);
          props.updateStatus();
          if(isReReg.isReReg===true){
            dispatch(setReRegistrationsReload(!reload));
            history.push("/reregStatus");
          }
        })
        
      })
      .catch(err => {
        setLoading(false);
        console.log("SchoolOfGreenside -> submitReRegistration : Error: " + err);
      })  
    }
  });

  const { values, errors, touched, handleSubmit, handleBlur, setValues } = formik;

  const saveSignature = () => {
    if (signaturePadRef.current.isEmpty()) {
        alert("Please provide a signature first.");
    } else {
        const dataURL = signaturePadRef.current.toDataURL();
        let base64_to_imgsrc = Buffer.from(dataURL, "base64").toString()
        //add the string to the state
        return base64_to_imgsrc
        // You can also upload this data URL to your server
    }
  };

  console.log(errors)
  const handleCheck = (e) => {
    // setValues({
    //   ...values,
    //   term_condn: e.target.checked
    // })
    setChecked(e.target.checked)
  }

  /* Multiple  Register For Field  start  */

  const handleAddRegFor = () => {
    const regList = [...registerFor];
    regList.push("");
    // setValues({ ...values, registerFor: [...regList] });
    setRegisterFor([...regList])
  }

  const handleChangeRegisterFor = (e) => {   
    
    if(e.target.value!=""){
      // setValues({
      //   ...values,
      //   registerFor: e.target.value,
      //   repeat:[""]
      // });
      setRegisterFor(e.target.value)
      setRepeat([""])
      setDisableRep(true);
    }else{
      // setValues({
      //   ...values,
      //   registerFor: e.target.value
      // });
      setRegisterFor(e.target.value)
      setDisableRep(false);
    }
  }

  const handleDeleteRegFor = (index) => {
    const regList = [...registerFor];
    regList.splice(index, 1);
    // setValues({ ...values, registerFor: [...regList] });
    setRegisterFor([...regList])
  }

  /* Multiple  Register For Field  end  */


  /* Multiple  Repeat Field  start  */
  const handleAddRepeat = () => {
    const repeatList = [...repeat];
    repeatList.push("");
    // setValues({ ...values, repeat: [...repeatList] });
    setRepeat([...repeatList])
  }

  const handleChangeRepeat = (e, idx) => {
    let RepeatList = [...repeat];
    RepeatList[idx] = e.target.value;
    let flag = true
    RepeatList.map((element)=>{
      if(element!=""){
        flag=false;
      }
    })
    if(flag){
      setDisableReg(false);
    }else{
      setDisableReg(true);
    }
    // setValues({
    //   ...values,
    //   repeat: [...RepeatList]
    // });
    setRepeat([...RepeatList])
  }

  const handleDeleteRepeat = (index) => {
    const repeatList = [...repeat];
    repeatList.splice(index, 1);
    let flag = true
    repeatList.map((element)=>{
      if(element!=""){
        flag=false;
      }
    })
    if(flag){
      setDisableReg(false);
    }else{
      setDisableReg(true);
    }
    // setValues({ ...values, repeat: [...repeatList] });
    setRepeat([...repeatList])
  }
  /* Multiple  Repeat Field  end  */


  return (
    <div className="card-body">
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <div className="theme-form application-form">
            <div className="row">
              <div className="form-group col-sm-12 inputfield">
                <h5 className="textdanger heading-5">Register For:</h5>
                <label>
                Should you have passed your current level, as per your transcript results, please choose the programme you would like to register for/register for the next level of your programme.
                </label>
                <div className="form-group inputfield col-12 col-md-6 px-0">
                <select
                          className={"form-control " + (touched.registerFor && errors.registerFor ? " is-invalid" : "")}
                          name="registerFor"
                          id="registerFor"
                          disabled={disableReg}
                          value={values.registerFor}
                          onChange={(e) => { handleChangeRegisterFor(e) }}
                        >
                          <option value="">---</option>
                          <option value="1">First Year - Bachelor ofArts Degree in Interior Design</option>
                          <option value="2">First Year - Bachelor of Arts Degree in Graphics Design</option>
                          <option value="3">First Year - Bachelor of Arts Degree in Multimedia Design</option>
                          <option value="4">Second Year - BA Graphic Design</option>
                          <option value="5">Second Year - BA Multimedia Design</option>
                          <option value="6">Second Year - BA Interior Design</option>
                          <option value="7">Third year-Graphic Design</option>
                          <option value="8">Third year-Interior Design</option>
                          <option value="9">Third year Multimedia Design</option>
                          <option value="10">BA Honours-Interior Design</option>
                          <option value="11">BA Honours Multimedia Design</option>
                          <option value="12">BA Honours-Visual Communication</option>
                        </select>
                </div>
              </div>
              <div className="form-group col-sm-12 inputfield">
                <h5 className="textdanger heading-5">Repeat:</h5>
                <label>
                  Refer to your transcript to determine which modules you will need to repeat. Select all appropriate modules.
                </label>
                <div className="form-group inputfield col-12 px-0">
                  {values.repeat.map((element, idx) => (
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <select
                          className={"form-control " + (touched.repeat && errors.repeat ? " is-invalid" : "")}
                          name="registerFor"
                          id="repeat"
                          value={element}
                          disabled={disableRep}
                          onChange={(e) => { handleChangeRepeat(e, idx) }}
                        >
                          <option value="">---</option>
                          {
                            repeatOptions.filter(option => !values.repeat.some((v) => values.repeat[idx] != option.value && v == option.value )).map((option, idx) => { 
                              return (
                                <option key={idx} value={option.value}>{option.label}</option>
                              )
                            })
                          }
                        </select>
                      </div>
                      {values.repeat.length > 1 && <div className="col-2 mb-2">
                        <button onClick={() => { handleDeleteRepeat(idx) }} type="button" class="btn btn-danger rounded-circle" title="Delete"><i class="fal fa-trash-alt"></i></button>
                      </div>}
                    </div>
                  ))}
                  { values.repeat.length < repeatOptions.length && <div className="form-group m-0">
                    <button
                      className="btn btn-link p-0 btn-additional"
                      onClick={() => handleAddRepeat()}
                      type='button'
                      disabled={disableRep}
                    >
                      <i className="fal fa-plus"></i> Add additional line item
                    </button>
                  </div>}
                </div>
                <DigitalSignatureComponent isCancel={isCancel} formik={formik} signaturePadRef={signaturePadRef} fileInputRef={fileInputRef} cDate={cDate} ipAddress={ipAddress} setIpAddress={setIpAddress} setCDate={setCDate} setIsSig={setIsSig}/>
              </div>
              <div className="col-12 form-group inputfield">
                <div className="custom-control custom-checkbox text-left box-tm">
                  <input
                    className={
                      "custom-control-input " +
                      (touched.term_condn && errors.term_condn
                        ? " is-invalid"
                        : "")
                    }
                    type="checkbox"
                    name="term_condn"
                    onChange={(e) => handleCheck(e)}
                    onBlur={handleBlur}
                    checked={checked}
                    id="customCheck1"
                  />

                  <label
                    className="custom-control-label textdanger"
                    htmlFor="customCheck1"
                  >
                    I hereby confirm that I have read, understood and accepted
                    all <a href={registration?.linkTandC} target="_blank">Terms and Conditions</a> as set out in the application form that the above subject selections are correct, and understand
                    that there may be changes made based on the academic outcome.
                  </label>
                  {formik.touched.term_condn && formik.errors.term_condn ? (
                    <div className="invalid-feedback">
                      {formik.errors.term_condn}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="Fmfooter">
            <button
              title="Submit"
              type="submit"
              className="btn btn-submit btn-main btn-primary"
              disabled={loading}
            >
              {loading && <i className="fal fa-cog fa-spin"></i>} Submit
            </button>
            <button title="Cancel" onClick={handleCancel} type='button' className="btn btn-submit btn-main btn-danger ml-2">
              Cancel
            </button>
          </div>
        </Form>
      </FormikProvider>
    </div>
  )
}
