import { FastField } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { BASE_URL, DOWNLOAD_FILE_URI, STUDENT_FILE_URI, zipURL } from '../../utils/Constants';
import formatFileURL from './formatUrl';

const UploadSignatureFile = ({fileInputRef, formik, setShowIp, readOnly=false, readOnlyData, setIsSig, isCancel}) => {
  const [dragActive, setDragActive] = useState(false);
  const [image, setImage] = useState(null);
  const [readImage, setReadImage] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    if(isCancel){
      setImage(null);
      setShowIp(null);
    }
  },[isCancel])

  useEffect(() => {
    if (readOnly && readOnlyData) {
      setImageLoading(true);
      setImageError(false);
      
      // var readImage = readOnlyData?.student_signature?.startsWith("http") || readOnlyData?.student_signature?.startsWith("https")
      //   ? zipURL !== ""
      //     ? zipURL + readOnlyData?.student_signature?.replace("/home/myaie/public_html/", "").replace("public/", "").replace(/https?:\/\//, "")
      //     : readOnlyData?.student_signature?.replace("/home/myaie/public_html/", "").replace("public/", "").replace(/https?:\/\//, "")
      //   : zipURL + (BASE_URL?.replace(/https?:\/\//, "") || "") +
      //     (readOnlyData?.student_signature?.replace("/home/myaie/public_html/", "").replace("public/", "").startsWith("/") ? "" : "/") +
      //     readOnlyData?.student_signature?.replace("/home/myaie/public_html/", "").replace("public/", "");

      var readImage = formatFileURL(readOnlyData?.student_signature)

      // Create a new image object to test the loading
      if(readImage && !readImage.endsWith('undefined')){
        const img = new Image();
        
        img.onload = () => {
          setImageLoading(false);
          setReadImage(readImage);
        };
        
        img.onerror = () => {
          setImageLoading(false);
          setImageError(true);
        };

        img.src = readImage;
      }
    }
  }, [readOnly, readOnlyData]);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files);
      formik.setFieldValue('student_signature', e.dataTransfer.files[0]);
      setIsSig(e.dataTransfer.files[0]);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files);
      formik.setFieldValue('student_signature', e.target.files[0]);
      setIsSig(e.target.files[0]);
    }
  };

  const handleFiles = (files) => {
    setShowIp(true);
    const file = files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="max-w-md position-relative signature-pad-outer p-4 border border-gray-300 rounded-lg">
      {(image || readOnly) ? (
        <>
          {imageLoading ? (
            <div className="text-center p-4">
              <div className="spinner-border text-primary" role="status"> 
              </div>
            </div>
          ) : imageError ? (
            <div className="text-center text-danger p-4">
              Failed to load image. Please try again.
            </div>
          ) : (
            <>
              <img 
                src={readOnly ? readImage : image} 
                style={{color: '#467FCF', maxHeight: "175px"}} 
                alt="Uploaded" 
                className="max-w-full m-auto d-block object-contain" 
              />
              <div 
                className={`${readOnly ? 'd-none' : ''} position-absolute`} 
                style={{
                  cursor: readOnly ? "" : 'pointer',
                  color: '#467FCF',
                  top: '6px',
                  right:'10px'
                }} 
                onClick={() => {
                  setImage(null);
                  setShowIp(false);
                  formik.setFieldValue('student_signature', null);
                  setIsSig(null);
                }}
              >
                Remove Uploaded Image<i className='fal ml-2 fa-trash-alt'></i>
              </div>
            </>
          )}
        </>
      ) : (
        <label 
          htmlFor="file-upload" 
          className={`border-2 border-dashed rounded-lg p-6 text-center ${
            dragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
          }`}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          <i className='fal fa-upload' style={{fontSize: '48px', color: '#467FCF'}}></i>
          <p className="mb-0 text-gray-600 mt-3">
            To upload your signature, Drop your file here, or{' '}
            <label 
              htmlFor="file-upload" 
              className="font-medium fw-bold text-blue-600 hover:underline cursor-pointer"
              style={{color: "#467FCF", cursor: 'pointer'}}
            >
              click here to browse
            </label>
          </p>
          <p className="mt-1 text-xs" style={{color: "#467FCF"}}>
            Supported Files: JPG and PNG
          </p>
          <input
            id="file-upload"
            name="file-upload"
            type="file"
            className="d-none"
            ref={fileInputRef}
            onChange={handleChange}
            accept=".jpg,.png"
          />
        </label>
      )}
    </div>
  );
};

export default UploadSignatureFile;