import React from "react";

function ReReSubject(props) {
  const { reRegSubject,fields } = props;
  let subjectType = {
    full: "Full-Time",
    cores: "Correspondence",
    part: "Part-Time",
    fulltimeonline: "Full Time (Online)",
    fulltimecontact: "Full Time (Contact)",
    parttimeonline: "Part-Time (Online)",
  };
  return (
    <>
      {reRegSubject?.map((element,index) => {
        return (
          
            element.number != undefined && element.number != null ?
            (
              <option
              key={index}
                value={element.intakeID || element?.netsuite_product_id}
              >{`${element.courseName || element.name} - {(Intake ${element.number}-${element.year})}`}</option>
            ) : 
            (
              <option
              key={index}
                value={element.intakeID || element?.netsuite_product_id}
              >{`${element?.courseName || element?.name} (${element?.credits} Credits)`}</option>
            )
          
         
        );
      })}
    </>
  );
}

export default ReReSubject;
