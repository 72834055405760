import React, { useEffect, useState } from "react";
import ReReSubject from "./ReReSubject";

function ProgressForm(props) {
  const {
    formik,
    setparentSubjectProgLength,
    parentSubjectProgLength,
    reRegSubject,
    emptyFields
  } = props;

  const [fields, setFields] = useState([{ subject: "" }]);

  useEffect(() => {
    if(emptyFields) {
      setFields([{ subject: "" }]);
    }
  },[emptyFields])

  function handleAdd() {
    const values = [...fields];
    values.push({ subject: "" });
    setFields(values);
    props.setparentSubjectProgLength(values);
  }

  function handleRemove(i) {
    const values = [...fields];
    if (values.length > 1) {
      values.splice(i, 1);
      setFields(values);
      props.setparentSubjectProgLength(values);
    }
  }

  function handleSubjectChange(i, event) {
    const values = [...fields];
    values[i].subject = event.target.value;
    setFields(values);
    props.setparentSubjectProgLength(values);
  }

  return (
    <>
      {formik.values.subjectProgress_.map((item, idx) => (
        <React.Fragment key={idx}>
          <div className="row">
            <div className="col-sm-6 d-flex gap-2">
              <select
                className="form-control"
                name={`subjectProgress_[${idx}].subject`}
                onChange={(e) => handleSubjectChange(idx, e)}
                onBlur={formik.handleBlur}
                key={idx}
                value={formik.values.subjectProgress_[idx].subject}
              >
                <option value="">---</option>
                <ReReSubject reRegSubject={reRegSubject.filter(i => !fields.some(field => (fields[idx].subject != i.intakeID && field.subject == i?.intakeID)))} />
              </select>

              {formik.touched.subjectProgress_ &&
              formik.errors.subjectProgress_ &&
              formik.touched.subjectProgress_[idx] &&
              formik.errors.subjectProgress_[idx] &&
              formik.touched.subjectProgress_[idx].subject &&
              formik.errors.subjectProgress_[idx].subject ? (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {formik.errors.subjectProgress_[idx].subject}
                </div>
              ) : null}
            <div className="col pl-0" title="Delete">
              <a className="btn btn-danger" onClick={() => handleRemove(idx)}>
                <i className="fal fa-trash-alt"></i>
              </a>
            </div>
            </div>
          </div>
        </React.Fragment>
      ))}
      {
        fields.length < reRegSubject.length &&
        <React.Fragment>
          <div className="form-group m-0">
            <a
              className="btn btn-link p-0 btn-additional"
              onClick={() => handleAdd()}
            >
              <i className="fal fa-plus"></i> Add additional line item
            </a>
          </div>
        </React.Fragment>
      }
    </>
  );
}

export default ProgressForm;
