import React, { useEffect, useState } from "react";
import ReReSubject from "./re_registration_sub/ReReSubject";

function BachleorModuleForm(props) {
  const {
    formik,
    setparentSubjectReSubLength,
    parentSubjectReSubLength,
    reRegSubject,
    selectedData,
    progressionData,
    modulesData,
    moduleType,
  } = props;

  const [fields, setFields] = useState([""]);
  const [fields1, setFields1] = useState([]);
  const [regDropdown, setRegDropdown] = useState([]);
  const [maxDropdown, setMaxDropdown] = useState();
  const [inputRenderer, setInputRenderer] = useState([]);
  const [optionArr, setOptionArr] = useState([]);
  
  // Track selections for each dropdown group using moduleKey
  const [selectedValuesByGroup, setSelectedValuesByGroup] = useState({});

  useEffect(() => {
    let filteredKeys = Object.keys(progressionData).filter(key => !isNaN(key));
    setRegDropdown([progressionData[filteredKeys[0]], progressionData[filteredKeys[1]]]);
  }, [progressionData]);

  useEffect(() => {
    let updatedData = {};
    modulesData.forEach((d) => {
      updatedData[d.key] = [""];
    });
    props.setparentSubjectReSubLength(updatedData);

    // Initialize selections for each module
    const initialSelectedValues = {};
    modulesData.forEach((d) => {
      initialSelectedValues[d.key] = [];
    });
    setSelectedValuesByGroup(initialSelectedValues);
  }, []);

  function handleAdd(moduleKey) {
    const values = [...parentSubjectReSubLength[moduleKey]];
    values.push("");
    setFields(values);
    setparentSubjectReSubLength({
      ...parentSubjectReSubLength,
      [moduleKey]: values
    });
  }

  function handleRemove(idx, flag = 0, moduleKey) {
    const values = [...parentSubjectReSubLength[moduleKey]];
    if (values.length > 1) {
      // Remove the selected value from tracking for this module
      const newSelectedValues = { ...selectedValuesByGroup };
      newSelectedValues[moduleKey] = newSelectedValues[moduleKey].filter((_, index) => index !== idx);
      setSelectedValuesByGroup(newSelectedValues);
      
      values.splice(idx, 1);
      setFields(values);
      props.setparentSubjectReSubLength({
        ...parentSubjectReSubLength,
        [moduleKey]: values
      });
    }
  }

  function handleSubjectChange(idx, event, moduleKey) {
    const values = [...parentSubjectReSubLength[moduleKey]];
    values[idx] = event.target.value;
    setFields(values);
    props.setparentSubjectReSubLength({
      ...parentSubjectReSubLength,
      [moduleKey]: values
    });

    // Update selected values for this specific module
    const newSelectedValues = { ...selectedValuesByGroup };
    newSelectedValues[moduleKey] = values;
    setSelectedValuesByGroup(newSelectedValues);
  }

  const getTotalCredits = (array) => {
    let totalCredits = 0;
    const totality = array.map((d, i) => {
      totalCredits += d.credits;
      if (i === array.length - 1) return ` (${totalCredits} Credits)`;
    });
    return totality;
  };

  // Get available options for a specific dropdown within its module
  const getAvailableOptions = (moduleKey, currentIndex) => {
    const currentModuleSelections = selectedValuesByGroup[moduleKey] || [];
    
    return (modulesData.find(m => m.key === moduleKey)?.modules.modules || [])
      .filter(item => {
        const productId = item.netsuite_product_id?.toString();
        // Allow if:
        // 1. Not selected in this module group
        // 2. Selected in current position of this module group
        return !currentModuleSelections.includes(productId) || 
               currentModuleSelections[currentIndex] === productId;
      });
  };

  return (
    <>
      <h5 className="textdanger heading-5 mt-15">{moduleType.startsWith('c') ? 'Core' : moduleType.startsWith('f') ? 'Foundation' : 'Elective'} Modules</h5>
      {modulesData.map((d, moduleIdx) => (
        <div key={moduleIdx} className="mb-2 mt-3">
          <b>{d.name}</b>
          {getTotalCredits(d.modules.modules)}
          <span>
            <b className="credits-outstanding ml-2">
              Credits Outstanding: {d.modules.credits_outstanding != null ? d.modules.credits_outstanding : d.modules["credits outstanding"]}
            </b>
          </span>
          {(formik.values[moduleType.startsWith('c') ? 'core_arr' : moduleType.startsWith('f') ? 'foundation_arr' : 'elective_arr'][d.key] || [""]).map((item, idx) => (
            <div key={idx} className="row">
              <div className="col-sm-6 mt-2 d-flex gap-2">
                <select
                  className="form-control"
                  name={`subjectReSub_[${idx}].subject`}
                  onChange={(e) => handleSubjectChange(idx, e, d.key)}
                  onBlur={formik.handleBlur}
                  value={item}
                >
                  <option value="">Select</option>
                  <ReReSubject 
                    reRegSubject={getAvailableOptions(d.key, idx)}
                    fields={fields}
                  />
                </select>

                {formik.touched.subjectReSub_ &&
                formik.errors.subjectReSub_ &&
                formik.touched.subjectReSub_[idx] &&
                formik.errors.subjectReSub_[idx] &&
                formik.touched.subjectReSub_[idx].subject &&
                formik.errors.subjectReSub_[idx].subject ? (
                  <div className="invalid-feedback" style={{ display: "block" }}>
                    {formik.errors.subjectReSub_[idx].subject}
                  </div>
                ) : null}
                <a
                  href="#"
                  className="btn btn-danger"
                  onClick={() => handleRemove(idx, moduleIdx, d.key)}
                >
                  <i className="fal fa-trash-alt"></i>
                </a>
              </div>
            </div>
          ))}
          {(formik.values[moduleType.startsWith('c') ? 'core_arr' : moduleType.startsWith('f') ? 'foundation_arr' : 'elective_arr'][d.key]?.length !== d.modules.modules.length) && (
            <div className="form-group m-0">
              <a
                href="#"
                className="btn no-shadow btn-link p-0 btn-additional"
                onClick={() => handleAdd(d.key)}
              >
                Add additional line item <i className="fal fa-plus ml-2"></i>
              </a>
            </div>
          )}
        </div>
      ))}
    </>
  );
}

export default BachleorModuleForm;